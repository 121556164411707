<template>
  <div>
    <!-- Table Container Card -->

    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Date picker -->
        <b-col cols="12" md="6">
          <b-row>
            <b-col md="4 p-0 mr-1">
              <label for="example-input">From Date</label>
              <b-form-datepicker
                id="from-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.from_date"
              />
            </b-col>
            <b-col md="4 p-0">
              <label for="example-input">To Date</label>
              <b-form-datepicker
                id="to-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.to_date"
              />
            </b-col>
            <b-col md="2 d-flex align-items-end">
              <b-button @click="getMedicineList()" variant="success ">
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
          class="mt-2 d-flex justify-content-end align-items-center"
        >
          <div>
            <b-form-input
              type="text"
              class="form-control"
              placeholder="Search"
              @input="getMedicineList()"
              v-model.trim="search_filter.input_field"
              style="border-radius: 4px"
            />
          </div>
          <b-button
            @click="clearFilter"
            variant="secondary
 			 ml-1"
          >
            Clear
          </b-button>
          <b-button v-b-modal.add-medicine variant="primary mr-1 ml-2">
            Add
          </b-button>
          <b-button @click="excelDownload()" variant="warning">
            <feather-icon icon="DownloadIcon" size="16" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="medicineColumns && medicineColumns.total > 0">
      <b-table
        :items="medicineColumns.data"
        id="medicineTable"
        responsive
        :fields="fields"
        empty-text="No matching records found"
        class="position-relative"
      >
        <template #cell(index)="data">
          <div class="ml-1">
            {{ medicineColumns.from + data.index }}
          </div>
        </template>
        <template #cell(unit)="data">
          <div class="ml-1">
            {{ data.item.unit.name }}
          </div>
        </template>
        <template #cell(route)="data">
          <div class="ml-1">
            {{ data.item.route.name }}
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="EditIcon"
              size="16"
              v-b-modal.edit-medicine
              @click="() => getMedicineById(data.item.id)"
            />

            <b-tooltip
              title="Edit"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />

            <feather-icon
              @click="() => setMedicineId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-medicine
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mt-2 mb-5">
        <b-pagination
          :value="medicineColumns.current_page"
          :total-rows="medicineColumns.total"
          :per-page="medicineColumns.per_page"
          align="right"
          @change="getMedicineList"
        >
        </b-pagination>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center py-5">
      <b-img
        :src="
          require('@/assets/images/svg/infographics/purchase_medicine_infographic.svg')
        "
        class="mr-2"
      />
      <h4 class="mt-2 Secondary">No medicine added yet</h4>
    </div>
    <!-- Medicine modal -->
    <b-modal
      id="add-medicine"
      title="Add Medicine"
      ok-only
      @ok="addMedicine"
      ok-title="Submit"
      ref="my-modal"
	   @show="resetModal"
      @hidden="resetModal"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit="addMedicine" method="post">
          <b-form-group>
            <template v-slot:label>
              Enter Medicine Name <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Medicine Name"
              rules="required"
            >
              <b-form-input
                v-model="formData.disinfectant_name"
                name="medicineName"
                placeholder="Enter Medicine Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Unit Name <span class="text-danger">*</span>
            </template>

            <validation-provider
              #default="{ errors }"
              name="Unit Name"
              rules="required"
            >
              <v-select
                v-model="formData.unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="unitList"
                label="name"
                :reduce="(e) => e.id"
                clearable:false
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Route Name <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Route Name"
              rules="required"
            >
              <v-select
                v-model="formData.route"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="routeList"
                label="name"
                :reduce="(e) => e.id"
                clearable:false
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Expiry Date<span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Expiry Date"
              rules="required"
            >
              <b-form-datepicker
                v-model="formData.expiry_date"
                placeholder="Choose a date"
                local="en"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Edit  modal -->
    <b-modal
      id="edit-medicine"
      title="Edit Medicine"
      ok-only
      @ok="updateMedicine"
      ok-title="Submit"
      ref="my-modal"
      @show="resetModal"
      @hidden="resetModal"
    >
      <b-form method="post">
        <b-form-group
          label="Enter Medicine Name"
          label-for="Enter Medicine Name "
        >
          <b-form-input
            v-model="formData.disinfectant_name"
            name="medicineName"
            placeholder="Enter Vaccine Name"
          />
        </b-form-group>
        <b-form-group label="Unit Name" label-for="Unit Name ">
          <div class="form-label-group">
            <v-select
              :options="unitList"
              v-model="formData.unit"
              label="name"
              clearable:false
            />
          </div>
        </b-form-group>
        <b-form-group label="Route Name" label-for="Route ">
          <div class="form-label-group">
            <v-select
              v-model="formData.route"
              :options="routeList"
              label="name"
              clearable:false
            />
          </div>
        </b-form-group>
        <b-form-group label="Expiry Date" label-for="date ">
          <div class="form-label-group">
            <b-form-datepicker
              v-model="formData.expiry_date"
              placeholder="Choose a date"
              local="en"
            />
          </div>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- delete modal -->
    <DeleteModal id="modal-medicine" :onClicked="deleteMedicine" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BImg,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTabs,
  BTab,
  BCardText,
  BFormDatepicker,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
export default {
  components: {
    BCard,
    BRow,
    BImg,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardText,
    BTabs,
    BTab,
    vSelect,
    BFormDatepicker,
    BFormSelect,
    BFormTextarea,
    DeleteModal,
  },
  data() {
    return {
      selectedUnit: null,
      selectedRoute: null,
      unitList: null,
      routeList: null,
      company_name: null,
      value: null,
      page: null,
      searchQuery: null,
      formData: {
        disinfectant_name: null,
        unit: null,
        route: null,
        expiry_date: null,
      },
      type: "medicine",
      search_filter: {
        input_field: null,
        to_date: null,
        from_date: null,
      },
      fields: [
        { key: "index", label: "S.no" },
        ,
        "disinfectant_name",
        { key: "unit", label: "unit" },
        { key: "route", label: "route" },
        {
          key: "expiry_date",
          label: "expiry date",
          sortable: true,
          formatter: (value) => {
            return moment(value).format("MMMM Do YYYY");
          },
        },
        "Actions",
      ],
      medicineName: null,
      medicineColumns: {},
      required,
      email,
    };
  },
  created: function () {
    this.getUnit();
    this.getRoute();
  },
  mounted: function () {
    this.getMedicineList();
  },

  methods: {
    moment() {
      return moment();
    },
    resetModal() {
      this.formData.disinfectant_name = "";
      this.formData.unit = "";
      this.formData.route = "";
      this.formData.expiry_date = "";
    },
    getMedicineList(pageNo = 1) {
      let url = `web/disinfectants`;
      let queryParams = {
        page: pageNo,
      };

      if (this.search_filter.input_field) {
        queryParams.input_field = this.search_filter.input_field;
      }
      if (this.search_filter.to_date) {
        queryParams.to_date = this.search_filter.to_date;
      }
      if (this.search_filter.from_date) {
        queryParams.from_date = this.search_filter.from_date;
      }
      axiosIns
        .get(this.addQueryString(url, queryParams))
        .then((response) => {
          this.medicineColumns = response.data.disinfectant_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getUnit() {
      axiosIns
        .get(`web/units/${this.type}`)
        .then((response) => {
          this.unitList = response.data.unitList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getRoute() {
      axiosIns
        .get(`web/routeType/${this.type}`)
        .then((response) => {
          this.routeList = response.data.routeList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    addMedicine(e) {
      e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var formData = new FormData();
          let form_data = this.objectToFormData(this.formData, formData);
          axiosIns
            .post(`web/disinfectants`, form_data)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data successfully Added `,
                },
              });
              this.$refs["my-modal"].hide();
              this.getMedicineList();
            })
            .catch((error) => {
              const data = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: data,
                },
              });
              this.getMedicineList();
            });
        }
      });
    },
    getMedicineById(id) {
      axiosIns
        .get(`web/disinfectants/${id}`)
        .then((response) => {
          this.formData = response.data.disinfectant;
          this.id = id;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    updateMedicine() {
      var formData = new FormData();
      formData.append("_method", "PUT");
      let form_data = this.objectToFormData(this.formData, formData);
      axiosIns
        .post(`web/disinfectants/${this.id}`, form_data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "warning",
              text: `Data Updated Successfully `,
            },
          });
          this.getMedicineList();
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
          this.getMedicineList();
        });
    },
    setMedicineId(id) {
      this.id = id;
    },
    deleteMedicine() {
      var data = new FormData();
      data.append("_method", "DELETE");
      axiosIns
        .post(`web/disinfectants/${this.id}`, data)
        .then((response) => {
          response;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: `Data successfully Deleted `,
            },
          });
          this.getMedicineList();
        })
        .catch((error) => {
          const data = error.response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
          this.getMedicineList();
        });
    },

    excelDownload() {
      if (this.search_filter.from_date && this.search_filter.to_date) {
        this.search_filter.from_date = moment(
          this.search_filter.from_date
        ).format("YYYY-MM-DD");
        this.search_filter.to_date = moment(this.search_filter.to_date).format(
          "YYYY-MM-DD"
        );
      }
      let order_filter_data = this.prepareForm(this.search_filter);
      axiosIns
        .post(`web/export-disinfectant`, order_filter_data)
        .then((response) => {
          var tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = response.data.file;
          tempLink.setAttribute("download", response.data.name);
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          this.getMedicineList();
        })
        .catch((error) => {
          error.response;
          const data = error.response.data.errors;
          data, "data";
          let arr = Object.values(data).flat().toString(",");
          arr, "array";
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: arr,
            },
          });
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getMedicineList();
        });
    },
    clearFilter() {
      this.search_filter.input_field = "";
      this.search_filter.to_date = "";
      this.search_filter.from_date = "";
      this.getMedicineList();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
