import { render, staticRenderFns } from "./VaccineList.vue?vue&type=template&id=3a6254b1&scoped=true&"
import script from "./VaccineList.vue?vue&type=script&lang=js&"
export * from "./VaccineList.vue?vue&type=script&lang=js&"
import style1 from "./VaccineList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a6254b1",
  null
  
)

export default component.exports